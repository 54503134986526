

















































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import ChannelMessageFilterHandlerModuleBuilder
  from "@/includes/logic/Modules/models/modules/filters/ChannelMessageFilterHandlerModule/ChannelMessageFilterHandlerModuleBuilder";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import ModuleConfigSettings from '../../components/ModuleConfigSettings.vue'
import ModuleConfigSettingsWrapper from '../../components/ModuleConfigSettingsWrapper.vue'
import FilterJournal from "../../components/filters/FilterJournal.vue";
import FilterCommonSettings from "../../components/filters/FilterCommonSettings.vue";
import FilterNewUsersSettings from "../../components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "../../components/filters/FilterRemoveEditedSetting.vue";
import FilterExtraSettings from "../../components/filters/FilterExtraSettings.vue";
import FilterResetPunishSettings from '../../components/filters/FilterResetPunishSettings.vue'
import FilterSettingLayout from "../../components/filters/layout/FilterSettingLayout.vue";

import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue';
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue';

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    FilterSettingLayout,
    FilterExtraSettings,
    FilterRemoveEditedSetting,
    FilterResetPunishSettings,
    FilterNewUsersSettings,
    FilterCommonSettings,
    FilterJournal,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    BlackWhiteListInput,
    NumberInput
  },
})
export default class ChannelMessageFilterHandlerModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper) {
  @VModel() module!: ChannelMessageFilterHandlerModuleBuilder
}
